import React from "react"

const WhyClearFactorBox = ({data, title, boxType = 'landscape', img, className}) => (
  <div className={`${className} ${boxType === 'portrait' ? 'md:row-span-2 bg-white rounded-2xl p-12 2xl:p-14' : 'bg-white rounded-2xl p-12 sm:flex justify-between items-center space-y-6 sm:space-y-0'} `}>
    <div className={boxType === 'portrait' ? 'space-y-6 h-3/4 sm:h-1/2' : 'space-y-6 sm:w-1/2'}>
      <h2 className="type-9xl text-blue-800 font-semibold">{title}</h2>
      <ul className="space-y-5 text-grey-500 type-base">
        {data.map(el => (
          <li key={el}>{el}</li>
        ))}
      </ul>
    </div>
    <div className={boxType === 'portrait' ? 'h-1/4 sm:h-1/2' : 'sm:w-1/2'}>
      <div className="mx-auto">
        {img}
      </div>
    </div>
  </div>
)

export default WhyClearFactorBox
