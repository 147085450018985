export const homeHeroList = [
  "Release cash trapped in outstanding invoices within 24hours!",
  "Simple transparent pricing",
  "No hidden fees",
  "No Debentures or Personal Guarantees",
  "Complete your application in under 5 minutes",
  "Only pay for the Cash you need",
  "Fund Invoices from £100 up",
]

export const whyClearFactorFair = [
  'Competitive marketplace means you always get the best rate*',
  'Small % fees',
  'Fill the invoice finance funding gap for all sizes of business',
  'Competitive minimums, no hidden fees or contractual binds'
]

export const whyClearFactorSimple = [
  'Central online platform',
  'As simple as 3 clicks',
  'Fully automated process'
]

export const whyClearFactorOpen = [
  'Invoice finance for all sizes of SME ',
  'Investment opportunities for individual investors as well as other businesses'
]
