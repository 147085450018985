import React from "react"
import ShadowBox from "./shadow-box"

const GetStartedBox = ({title, number, className, img}) => (
  <ShadowBox className={`${className} relative flex flex-col items-center py-5 space-y-6 w-full max-w-sm`}>
    <div className="type-3xl -top-4 -left-4 lg:-top-6 lg:-left-6 absolute flex items-center justify-center bg-green-500 text-white w-10 h-10 lg:h-14 lg:w-14 rounded-full z-1">
      {number ? number : '1'}
    </div>
    <div className="py-14"></div>
    <div className="absolute -top-14">
      {img}
    </div>
    <div className="type-3xl text-blue-300 font-semibold sm:pt-8 md:pt-0 xl:pt-10">{title}</div>
  </ShadowBox>
)

export default GetStartedBox
