import React, { useEffect, useRef, useState } from "react"
import { graphql } from "gatsby"
import lottie from "lottie-web"

// components
import Layout from "../components/layout"
// import BlogLink from "../components/blog-link" <-- add if using CMS
import ShadowBox from "../components/shadow-box"
import VideoSection from "../components/video-section"
import GetStartedBox from "../components/get-started-box"
import WhyClearFactorBox from "../components/why-clear-factor-box"
import InfoBlock from "../components/info-block"
import VideoModal from "../components/video-modal"
import { scrollAnimationHome } from "../components/scroll-animation"

// store
import {
  howItWorks,
  getStartedOne,
  getStartedTwo,
  getStartedThree,
  whyFair,
  whySimple,
  whyOpen,
} from "../store/images"
import {
  homeHeroList,
  whyClearFactorFair,
  whyClearFactorSimple,
  whyClearFactorOpen,
} from "../store/content"

const Home = ({
  data: {
    site,
    // allMarkdownRemark: { edges }, <-- add if using CMS
  },
}) => {
  // add if using CMS
  // const Posts = edges
  //   // .filter(edge => edge.node.frontmatter.date) <-------- You can filter your posts based on some criteria
  //   .map(edge => <BlogLink key={edge.node.id} post={edge.node} />)
  //   .slice(0, 6) // Decide how many to display

  // lottie animations
  // const hero = useRef(null);
  const ecosystemHelp = useRef(null)
  const hero = useRef(null)
  const trillionMarket = useRef(null)

  // video refs
  const videoOne = useRef(null)
  const videoTwo = useRef(null)
  const videoThree = useRef(null)

  //  modal - video states
  const [videoOneModal, setVideoOneModal] = useState(false)
  const [videoTwoModal, setVideoTwoModal] = useState(false)
  const [videoThreeModal, setVideoThreeModal] = useState(false)

  const playVideo = ref => ref.current.play()

  // close modal
  const onClose = () => {
    if (videoOneModal) {
      setVideoOneModal(false)
      videoOne.current.pause()
    } else if (videoTwoModal) {
      setVideoTwoModal(false)
      videoTwo.current.pause()
    } else if (videoThreeModal) {
      setVideoThreeModal(false)
      videoThree.current.pause()
    }
    return ""
  }

  const handleVideo = async (e, toggleState, ref) => {
    e.preventDefault()

    setTimeout(() => {
      playVideo(ref)
    }, 1000)

    return toggleState
  }

  useEffect(() => {
    scrollAnimationHome()

    lottie.loadAnimation({
      container: ecosystemHelp.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../assets/animations/ecosystem-help-animation.json"),
    })
    lottie.loadAnimation({
      container: hero.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../assets/animations/hero-animation.json"),
    })
    lottie.loadAnimation({
      container: trillionMarket.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../assets/animations/trillion-market-animation.json"),
    })
  }, [])

  return (
    <Layout title="Home" description={site.siteMetadata.description}>
      {/* hero */}
      <section
        style={{
          backgroundImage: `url(${"/assets/images/bg-blue-hero.svg"})`,
          backgroundPosition: "top -50px right -100px",
        }}
        className="site-container min-h-screen bg-blue-200 relative lg:flex items-center bg-no-repeat bg-contain pt-32 pb-24"
      >
        <div className="max-w-9xl mx-auto w-full lg:flex flex-col items-center space-y-10 lg:flex-row lg:space-x-12 lg:space-y-0">
          <h1 className="type-8xl font-semibold text-black lg:text-blue-800 lg:hidden">
            <div>The Fair Finance Revolution</div>
            <div>for Small Businesses</div>
          </h1>
          <div ref={hero} className="lg:w-1/2 lg:order-last"></div>
          <div className="lg:w-1/2 space-y-8 lg:flex flex-col items-center">
            <h1 className="hidden lg:block type-8xl font-semibold text-black lg:text-blue-800 max-w-3xl">
              <div>The Fair Finance Revolution</div>
              <div>for Small Businesses</div>
            </h1>
            <ShadowBox className="max-w-xl px-6 lg:px-10 py-12">
              <ul className="space-y-6">
                {homeHeroList.map(el => (
                  <li
                    key={el}
                    className="flex items-center space-x-4 type-xl text-grey-500"
                  >
                    <img src="/assets/icons/green-tick.svg" alt="Green tick" />
                    <p key={el}>{el}</p>
                  </li>
                ))}
                <div className="pt-10 flex justify-center">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://lt2nxmzm.sibpages.com/"
                    className="btn-green"
                  >
                    Join the fair finance revolution
                  </a>
                </div>
              </ul>
            </ShadowBox>
          </div>
        </div>
      </section>
      {/* global-economy */}
      <section className="site-container bg-blue-200 py-16">
        <VideoSection
          handleVideo={e =>
            handleVideo(e, setVideoOneModal(!videoOneModal), videoOne)
          }
        />
      </section>
      {/* get started */}
      <section className="site-container bg-blue-200 pt-16 pb-16 md:pb-0 text-center">
        <h2 className="type-6xl text-blue-500 font-semibold pb-16 md:pb-24 fade-in--6">
          How to get started
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10 max-w-8xl mx-auto place-items-center">
          <GetStartedBox
            title={"Apply online"}
            number={1}
            img={getStartedOne}
            className="md:-mt-16 slide-in--1"
          />
          <GetStartedBox
            title={"Submit your invoice"}
            number={2}
            img={getStartedTwo}
            className="md:-mb-16 slide-in--2"
          />
          <GetStartedBox
            title={"Get funded"}
            number={3}
            img={getStartedThree}
            className="md:-mb-44 slide-in--3"
          />
        </div>
      </section>
      {/* ecosystem */}
      <section className="site-container pt-16 pb-16 md:pt-48">
        <div className="flex flex-col items-center lg:flex-row space-y-8 p-8 2xl:p-16 max-w-xl lg:max-w-9xl mx-auto">
          <div className="lg:-ml-32">
            <div
              ref={ecosystemHelp}
              className="relative rounded-full h-500 w-500 lg:h-600 lg:w-600 xl:h-750 xl:w-750 2xl:h-900 2xl:w-900"
            >
              <button
                type="button"
                onClick={e =>
                  handleVideo(e, setVideoTwoModal(!videoTwoModal), videoTwo)
                }
              >
                <img
                  className="absolute z-1 h-24 w-24 mt-6 lg:w-36 lg:h-36 -translate-y-1/2 -translate-x-1/3 top-1/2 left-1/2 hover:scale-110 transform transition-all duration-300 ease-in-out"
                  src="/assets/icons/play-button.svg"
                  alt="Video play button"
                />
              </button>
            </div>
          </div>
          <div className="space-y-6 lg:w-1/2 box box4">
            <h2 className="type-6xl text-blue-500 font-semibold">
              How Clear Factor's Ecosystem Can Help You
            </h2>
            <p className="type-base text-grey-500">
              40% of SME’s that apply for finance, say that they apply for loans
              to cover a short-term shortfall in cashflow overlong-term growth.
            </p>
            <p className="type-base text-grey-500">
              Invoice finance gives you access to on-demand finance, while not
              having to expose yourself to long-term commitments.
            </p>
            <div className="flex flex-col lg:flex-row items-start space-y-4 lg:space-y-0 lg:space-x-8 pt-5">
              <button
                onClick={e =>
                  handleVideo(e, setVideoTwoModal(!videoTwoModal), videoTwo)
                }
                type="button"
                className="btn-light-blue w-60"
              >
                Watch Video
              </button>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://lt2nxmzm.sibpages.com/"
                className="btn-green w-60"
              >
                Get Started
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* why clear factor */}
      <section className="site-container bg-blue-300 py-16">
        <div className="space-y-12 max-w-8xl mx-auto">
          <div className="space-y-4 lg:space-y-0 lg:flex text-white">
            <h2 className="lg:w-1/2 type-9xl font-semibold fade-in--3">
              Why Clear Factor?
            </h2>
            <p className="lg:w-1/2 type-base lg:pt-3 fade-in--4">
              A revolutionary approach to providing businesses with essential
              cash flow. Clear Factor democratises invoice finance for
              businesses and investors alike.
            </p>
          </div>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            <WhyClearFactorBox
              title="Fair"
              data={whyClearFactorFair}
              boxType="portrait"
              img={whyFair}
              className="slide-in--5"
            />
            <WhyClearFactorBox
              title="Simple"
              data={whyClearFactorSimple}
              img={whySimple}
              className="slide-in--6"
            />
            <WhyClearFactorBox
              title="Open"
              data={whyClearFactorOpen}
              img={whyOpen}
              className="slide-in--7"
            />
          </div>
        </div>
      </section>
      {/* fair finance revolution */}
      <section className="site-container py-32 bg-green-500 text-white text-center">
        <div className="max-w-5xl mx-auto space-y-10">
          <h2 className="type-11xl font-semibold fade-in--1">
            Join The Fair Finance Revolution
          </h2>
          <p className="type-lg font-light max-w-3xl mx-auto fade-in--1">
            Sign up today to join the first global invoice finance ecosystem in
            the world where SMEs can sell their invoices and investors can
            participate in the buying of those invoices.{" "}
          </p>
          <a
            href="https://invoicefinance.clearfactor.io/"
            target="_blank"
            rel="noopener noreferrer"
            className="btn-white inline-flex"
          >
            Sign Up
          </a>
        </div>
      </section>
      {/* quote */}
      <section className="site-container relative bg-blue-200 text-center py-32 z-1">
        <div
          ref={trillionMarket}
          className="absolute lg:right-12 -top-12 h-600 w-600 2xl:h-750 2xl:w-750 2xl:right-40 2xl:-top-28 -z-1"
        ></div>
        <blockquote className="type-12xl font-bold text-blue-800 max-w-6xl mx-auto slide-in--8">
          ‘A $2.6 TRILLION MARKET EXISTS FOR INVOICE FINANCING'
        </blockquote>
      </section>
      {/* how it works */}
      <section className="site-container py-16 bg-blue-200 text-center">
        <ShadowBox className="px-2 py-16 w-full max-w-7xl mx-auto space-y-6 slide-in--9">
          <h2 className="type-9xl font-semibold text-blue-800">How it works</h2>
          {howItWorks}
        </ShadowBox>
      </section>
      {/* FAQ & become a partner */}
      <section className="site-container pt-16 pb-24 space-y-16 lg:space-y-0 flex flex-col items-center lg:flex-row lg:space-x-8 justify-center">
        <InfoBlock
          title="Become an introducer"
          image="/assets/images/become-a-partner.jpg"
          bodyText="We have multiple opportunities for brokers, accountants and client-facing institutions to help their clients cash flow needs."
          className="slide-in--12"
        />
        <InfoBlock
          title="FAQ's"
          link="/frequently-asked-questions"
          image="/assets/images/faq-block.jpg"
          bodyText="Here are some questions our customers ask. If there’s anything we haven’t covered here, check don't hesitate to contact us"
          className="slide-in--13"
        />
      </section>
      {/* display posts from CMS */}
      {/* POSTS */}
      {/* <section className="grid grid-cols-1 md:grid-cols-3 gap-5">
          {Posts}
        </section> */}
      {/* end */}
      {/* video modal */}
      {videoOneModal && (
        <>
          <VideoModal
            onClose={onClose}
            videoRef={videoOne}
            source="/video/introduction.mp4"
            poster="/assets/images/video-poster-one.jpg"
          />
        </>
      )}
      {videoTwoModal && (
        <>
          <VideoModal
            onClose={onClose}
            videoRef={videoTwo}
            source="/video/sme-process.mp4"
            poster="/assets/images/video-poster-two.jpg"
          />
        </>
      )}
      {videoThreeModal && (
        <>
          <VideoModal
            onClose={onClose}
            videoRef={videoThree}
            source="/video/auction-process.mp4"
            poster="/assets/images/video-poster-three.jpg"
          />
        </>
      )}
    </Layout>
  )
}

export default Home

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

// add GraphQL Query if using CMS

// allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
//   edges {
//     node {
//       id
//       excerpt(pruneLength: 250)
//       frontmatter {
//         date(formatString: "MMMM DD, YYYY")
//         path
//         title
//         thumbnail
//       }
//     }
//   }
// }
