import React from "react"
import { Link } from "gatsby"
import ShadowBox from "./shadow-box"

const InfoBlock = ({ title, bodyText, link, image, className }) => (
  <ShadowBox className={`max-w-2xl ${className}`}>
    <div
      style={{ backgroundImage: `url(${image})` }}
      className="rounded-2xl bg-center bg-no-repeat bg-cover h-60 sm:h-72"
    ></div>
    <div className="space-y-6 lg:space-y-0 lg:flex flex-col justify-between lg:h-96 px-6 py-10 lg:py-16 xl:py-20 2xl:py-10">
      <h2 className="type-4xl text-blue-500 font-semibold">{title}</h2>
      <p className="type-2xl text-blue-300 font-light">{bodyText}</p>
      <div>
        {link ? (
          <Link to={link ? link : "/"} className="btn-blue">
            Find Out More
          </Link>
        ) : (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://5cg7rzxf.sibpages.com/"
            className="btn-blue"
          >
            Find Out More
          </a>
        )}
      </div>
    </div>
  </ShadowBox>
)

export default InfoBlock
