import React, { useEffect, useRef } from "react"
import lottie from "lottie-web"
import ShadowBox from "./shadow-box"

const VideoSection = ({ handleVideo }) => {
  const globalEconomy = useRef(null)

  useEffect(() => {
    lottie.loadAnimation({
      container: globalEconomy.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require('../assets/animations/economy-animation.json')
    });
  }, [])

  return (
    <ShadowBox className="flex relative flex-col items-center lg:flex-row space-y-8 p-8 2xl:p-16 max-w-5xl 2xl:max-w-7xl mx-auto">
      <div className="absolute -top-56 lg:-top-44 lg:-right-52 2xl:-right-52 2xl:-top-56">
        <div
          ref={globalEconomy}
          className="relative rounded-full h-500 w-500 sm:h-600 sm:w-600 lg:h-750 lg:w-750 2xl:h-900 2xl:w-900"
        >
          <button type="button" onClick={handleVideo}>
            <img
              className="z-1 absolute h-24 w-24 lg:w-36 lg:h-36 -translate-y-1/2 -translate-x-1/3 top-1/2 mt-6 left-1/2 hover:scale-110 transform transition-all duration-300 ease-in-out"
              src="/assets/icons/play-button.svg"
              alt="Video play button"
            />
          </button>
        </div>
      </div>
      <div className="pt-44 sm:pt-64 lg:pt-0 space-y-5 lg:w-1/2">
        <h2 className="type-7xl text-blue-500 font-semibold">
          Small Businesses Power the Global Economy
        </h2>
        <h3 className="type-5xl text-blue-300 font-light">
          Why are so many denied access to fair finance?
        </h3>
        <p className="type-lg text-grey-500">
          Watch the video to find out more about ClearFactor's mission
        </p>
        <div className="flex flex-col lg:flex-row items-start space-y-4 lg:space-y-0 lg:space-x-8 pt-5">
          <button
            type="button"
            className="btn-light-blue w-60"
            onClick={handleVideo}
          >
            Watch Video
          </button>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://lt2nxmzm.sibpages.com/"
            className="btn-green w-60"
          >
            Get Started
          </a>
        </div>
      </div>
      <div className="hidden lg:block w-1/2"></div>
    </ShadowBox>
  )
}

export default VideoSection
